import {
  useFonts,
  Sarabun_100Thin,
  Sarabun_100Thin_Italic,
  Sarabun_200ExtraLight,
  Sarabun_200ExtraLight_Italic,
  Sarabun_300Light,
  Sarabun_300Light_Italic,
  Sarabun_400Regular,
  Sarabun_400Regular_Italic,
  Sarabun_500Medium,
  Sarabun_500Medium_Italic,
  Sarabun_600SemiBold,
  Sarabun_600SemiBold_Italic,
  Sarabun_700Bold,
  Sarabun_700Bold_Italic,
  Sarabun_800ExtraBold,
  Sarabun_800ExtraBold_Italic,
} from '@expo-google-fonts/sarabun';

//imports fonts from google fonts, and exports them as object
export function useAppFonts() {
  const [fontsLoaded] = useFonts({
    Sarabun_100Thin,
    Sarabun_100Thin_Italic,
    Sarabun_200ExtraLight,
    Sarabun_200ExtraLight_Italic,
    Sarabun_300Light,
    Sarabun_300Light_Italic,
    Sarabun_400Regular,
    Sarabun_400Regular_Italic,
    Sarabun_500Medium,
    Sarabun_500Medium_Italic,
    Sarabun_600SemiBold,
    Sarabun_600SemiBold_Italic,
    Sarabun_700Bold,
    Sarabun_700Bold_Italic,
    Sarabun_800ExtraBold,
    Sarabun_800ExtraBold_Italic,
  });

  return fontsLoaded;
}
