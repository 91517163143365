import React, { useEffect } from 'react';
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { ResetPassword } from '@/components/Auth/ResetPassword';
import { AuthStackParamList } from '@/navigation/AuthNavigator';

type AuthNavigationProp = NavigationProp<AuthStackParamList, 'AuthResetPassword'>;

export function ResetPasswordScreen() {
  const navigation = useNavigation<AuthNavigationProp>();
  const { params } = useRoute<RouteProp<AuthStackParamList, 'AuthResetPassword'>>();

  useEffect(() => {
    if (!params?.resetCode || !params?.email) {
      navigation.navigate('AuthSignIn');
    }
  }, [params?.resetCode, params?.email, navigation]);

  function linkToSignIn() {
    navigation.navigate('AuthSignIn');
  }

  function linkToForgotPassword() {
    if (params?.email) {
      navigation.navigate('AuthForgotPassword', { email: params.email });
    } else {
      navigation.navigate('AuthForgotPassword', { email: '' }); // Provide a fallback value
    }
  }

  return (
    <ResetPassword
      email={params?.email?.toLowerCase() || ''}
      resetCode={params?.resetCode || ''}
      linkToSignIn={linkToSignIn}
      linkToForgotPassword={linkToForgotPassword}
    />
  );
}

