import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  HStack,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
} from 'native-base';
import { useLinkTo } from '@react-navigation/native';

import { DeviceListDeviceFragment } from '@/graphql/components';

import { ChevronRightIcon } from '@/components/CustomIcons';
import { useCTSDimensions } from '@/hooks/useCTSDimensions';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';

interface Props {
  item: DeviceListDeviceFragment;
  selectedDevice: string;
  setSelectedDevice?: React.Dispatch<React.SetStateAction<string>>;
}

export function DeviceCard({ item, selectedDevice, setSelectedDevice }: Props) {
  const { t } = useTranslation();
  const linkTo = useLinkTo();
  const { isDesktopWeb } = useCTSDimensions();

  const cardColor = useThemeBackgroundColor('card');
  const selectedCardColor = useColorModeValue('white.200', 'black.600');
  const primary = useThemeFontColor('primary');
  const secondary = useThemeFontColor('secondary');

  const cardBG = item.id === selectedDevice ? selectedCardColor : cardColor;
  const deviceName = item?.alias ?? item?.name;
  const currentDeviceState = getDeviceState(item);

  interface DeviceState {
    state: 'online' | 'offline' | 'initiate';
    backgroundcolor: 'green.500' | 'red.500' | 'orange.500';
    message: string;
  }

  function getDeviceState(item: DeviceListDeviceFragment): DeviceState {
    if (item?.state === 'initiate') {
      return {
        state: 'initiate',
        backgroundcolor: 'orange.500',
        message: t('deviceEnrollDevice'),
      };
    }
    if (item?.status?.state === 'online') {
      return {
        state: 'online',
        backgroundcolor: 'green.500',
        message: t('online'),
      };
    }
    return {
      state: 'offline',
      backgroundcolor: 'red.500',
      message: t('offline'),
    };
  }

  const Status = () => (
    <HStack height="100%" alignItems="center">
      <Box
        width={1.5}
        height="95%"
        backgroundColor={currentDeviceState.backgroundcolor}
        borderRadius="xl"
      />
    </HStack>
  );

  return (
    <Pressable
      onPress={() => {
        linkTo(`/devices/${item.id}`);
        setSelectedDevice && setSelectedDevice(item.id);
      }}
      testID="device-card"
    >
      <HStack
        justifyContent="space-between"
        p={5}
        borderRadius={10}
        bg={cardBG}
      >
        <HStack space={3} flex={1}>
          <Status />
          <VStack maxW={['90%', '100%']}>
            {isDesktopWeb ? (
              <Text bold fontSize="xl" overflow="hidden">
                {deviceName}
              </Text>
            ) : (
              <Text bold fontSize="xl" overflow="hidden">
                {deviceName}
              </Text>
            )}
            <Text fontSize="md" color={secondary}>
              {currentDeviceState.message}
            </Text>
          </VStack>
        </HStack>
        <ChevronRightIcon color={primary} iconSize={5} />
      </HStack>
    </Pressable>
  );
}
