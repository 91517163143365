import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { AlertProvider } from '@/context/AlertContext';
import { CreateTenantScreen } from '@/screens/createTenantScreen';
import { ResetPasswordScreen } from '@/screens/resetPasswordScreen';
import { ForgotPasswordScreen } from '@/screens/forgotPasswordScreen';
import { SignInScreen } from '@/screens/signInScreen';
import { SignUpScreen } from '@/screens/signUpScreen';
import { VerifyEmailScreen } from '@/screens/verifyEmailScreen';

const Stack = createStackNavigator();

export type AuthStackParamList = {
  AuthCreateTenant: { initKey: string };
  AuthSignIn: undefined;
  AuthSignUp: { inviteCode: string, email: string };
  AuthForgotPassword: { email: string };
  AuthResetPassword: { resetCode: string; email: string };
  AuthCreateTenantScreen: { initKey: string };
  AuthVerifyEmail: { email?: string; verificationCode?: string };
};

export const AuthLinkingConfig = {
  path: 'auth',
  initialRouteName: 'AuthSignIn',
  screens: {
    AuthSignIn: {
      path: 'signIn',
    },
    AuthSignUp: {
      path: 'signUp',
    },
    AuthForgotPassword: {
      path: 'forgotPassword',
    },
    AuthResetPassword: {
      path: 'resetPassword',
    },
    AuthCreateTenant: {
      path: 'createTenant',
    },
    AuthVerifyEmail: {
      path: 'verifyEmail',
    },
  },
};

export function AuthNavigator() {
  return (
    <AlertProvider>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen
          name={'AuthSignIn'}
          component={SignInScreen}
          options={{ title: 'Sign in' }}
        />
        <Stack.Screen
          name={'AuthSignUp'}
          component={SignUpScreen}
          options={{ title: 'Sign up' }}
        />
        <Stack.Screen
          name={'AuthCreateTenant'}
          component={CreateTenantScreen}
          options={{ title: 'Register organization' }}
        />
        <Stack.Screen
          name={'AuthForgotPassword'}
          component={ForgotPasswordScreen}
          options={{ title: 'Forgot password' }}
        />
        <Stack.Screen
          name={'AuthResetPassword'}
          component={ResetPasswordScreen}
          options={{ title: 'Reset password' }}
        />
        <Stack.Screen
          name="AuthVerifyEmail"
          component={VerifyEmailScreen}
          options={{ title: 'Verify email' }}
        />
      </Stack.Navigator>
    </AlertProvider>
  );
}
