import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { KeyboardAvoidingView, Text } from 'native-base';
import { NetworkStatus } from '@apollo/client';

import {
  useListOrgInvitesQuery,
  useGetCurrentUserQuery,
  useSendOrgInviteEmailMutation,
  ListOrgInvitesDocument,
  ListOrgInvitesQuery,
} from '@/graphql/components';

import { AlertContext } from '@/context/AlertContext';
import { CustomBox } from '@/components/CustomBox';
import { Error } from '@/components/Error';
import { OrgInvitesContainer } from '@/containers/Settings/OrgInvitesContainer';
import { registerPageView } from '@/analytics/navigation';
import { useThemeBackgroundColor } from '@/hooks/useThemeColor';
import { useTranslation } from 'react-i18next';

export function OrgInvitesScreen(): React.ReactElement {
  const { t } = useTranslation();
  const bgColor = useThemeBackgroundColor('screen');
  const userQuery = useGetCurrentUserQuery();
  const CallAlert = React.useContext(AlertContext);

  const [fetchingMore, setFetchingMore] = React.useState<boolean>(false);

  useEffect(() => {
    registerPageView('orgInvites');
  }, []);

  const orgId = userQuery?.data?.getCurrentUser?.tenant?.id || '';

  const {
    data: invitesData,
    error,
    fetchMore,
    networkStatus,
    refetch,
  } = useListOrgInvitesQuery({
    variables: { orgId },
    notifyOnNetworkStatusChange: true,
  });

  const [sendOrgInviteEmail, { loading: sending }] = useSendOrgInviteEmailMutation({
    onCompleted: () => {
      CallAlert && CallAlert('Invite sent successfully', 'success');
    },
    onError: () => {
      CallAlert && CallAlert('Failed to send invite', 'error');
    },
    update: (cache, { data: mutationData }) => {
      if (!mutationData?.sendOrgInviteEmail) {
        return;
      }

      const existingData = cache.readQuery<ListOrgInvitesQuery>({
        query: ListOrgInvitesDocument,
        variables: { orgId },
      });

      const prev = existingData?.listOrgInvites;
      const prevItems = prev?.items ?? [];
      const newEmail = mutationData.sendOrgInviteEmail;

      const seen = new Set<string>();
      const mergedItems = [newEmail, ...prevItems].filter((item) => {
        if (seen.has(item.email)) return false;
        seen.add(item.email);
        return true;
      });

      cache.writeQuery<ListOrgInvitesQuery>({
        query: ListOrgInvitesDocument,
        variables: { orgId },
        data: {
          listOrgInvites: {
            __typename: prev?.__typename ?? 'TenantInviteConnection',
            items: mergedItems,
            nextToken: prev?.nextToken ?? null,
          },
        },
      });
    },
  });

  const fetchMoreInvites = async () => {
    const nextToken = invitesData?.listOrgInvites?.nextToken;
    if (!nextToken) return;

    setFetchingMore(true);
    await fetchMore({
      variables: { nextToken },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult?.listOrgInvites) return prev;

        const prevItems = prev.listOrgInvites?.items ?? [];
        const newItems = fetchMoreResult.listOrgInvites.items ?? [];
        const newNextToken = fetchMoreResult.listOrgInvites.nextToken ?? null;

        return {
          ...prev,
          listOrgInvites: {
            ...prev.listOrgInvites,
            items: [...prevItems, ...newItems],
            nextToken: newNextToken,
          },
        };
      },
    });
    setFetchingMore(false);
  };

  const sendInvite = (email: string) => {
    sendOrgInviteEmail({
      variables: {
        orgId,
        email,
      },
    });
  };

  const isLoading =
    networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch;

  if (isLoading) {
    return (
      <CustomBox type="slim">
        <Text>Loading...</Text>
      </CustomBox>
    );
  }

  if (error) {
    return <Error text={t('errorInvites')} retry={() => refetch()} />;
  }

  const invites = invitesData?.listOrgInvites?.items ?? [];
  const hasMore = Boolean(invitesData?.listOrgInvites?.nextToken);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1, backgroundColor: bgColor }}
    >
      <OrgInvitesContainer
        hasMore={hasMore}
        fetchMore={fetchMoreInvites}
        fetchingMore={fetchingMore}
        invites={invites}
        sending={sending}
        sendInvite={sendInvite}
      />
    </KeyboardAvoidingView>
  );
}
