import React, { useEffect, useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { TopBar } from '@/components/navigation/TopBar';

import { DashboardScreen } from '@/screens/DashboardScreen';
import { useInitialParams } from '@/hooks/useInitialParams';
import { useLinkTo } from '@react-navigation/native';

export type DashboardStackParamList = {
  dashboard: undefined;
};

const Stack = createStackNavigator<DashboardStackParamList>();

export const DashboardLinkingConfig = {
  path: '',
  screens: {
    Dashboard: {
      path: 'dashboard',
      exact: true,
    },
  },
};

export function DashboardChartNavigator(): React.ReactElement {
  const [, setReady] = useState(false);
  const linkTo = useLinkTo();
  const [params, setParams] = useInitialParams();

  useEffect(() => {
    if (params.initKey) {
      setParams({});
      linkTo(`/devices/enroll?initKey=${params.initKey}`);
    } else {
      setReady(true);
    }
  }, [params]);

  return (
    <Stack.Navigator initialRouteName="dashboard" screenOptions={{ headerShown: true }}>
      <Stack.Screen
        name="dashboard"
        component={DashboardScreen}
        options={{
          // headerShown: false,
          header: function Header() {
            return <TopBar title="Dashboard" />;
          },
          title: 'Dashboard',
        }}
      />
    </Stack.Navigator>
  );
}
