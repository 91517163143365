import React from 'react';
import { NavigationProp, useNavigation, useRoute, RouteProp } from '@react-navigation/native';

import { ForgotPassword } from '@/components/Auth/ForgotPassword';
import { AuthStackParamList } from '@/navigation/AuthNavigator';

type AuthNavigationProp = NavigationProp<AuthStackParamList, 'AuthForgotPassword'>;

export function ForgotPasswordScreen() {
  const navigation = useNavigation<AuthNavigationProp>();
  const { params } = useRoute<RouteProp<AuthStackParamList, 'AuthForgotPassword'>>();

  function linkToSignIn() {
    navigation.navigate('AuthSignIn');
  }

  return <ForgotPassword email={params?.email} linkToSignIn={linkToSignIn} />;
}

