import React, { createContext, useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import { Linking } from 'react-native';

interface InitialParams {
  initKey?: string;
}

type InitialParamsContext = [InitialParams, (params: InitialParams) => void];

const InitialParamsContext = createContext<InitialParamsContext>([
  {},
  () => {
    //
  },
]);

export function InitialParamsProvider({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  const state = useState<InitialParams>({});
  const [, setParams] = state;
  const [ready, setReady] = useState(false);


  useEffect(() => {
    Linking.getInitialURL()
      .then((initialUrl) => {
        const search = new URL(initialUrl || '').search;
        const params = queryString.parse(search);

        setParams({
          ...params,
        });
        setReady(true);
      })
      .catch((e) => {
        console.log('Error fetching initial URL:', e);
        setReady(true);
      });
  }, []);

  return (
    <InitialParamsContext.Provider value={state}>
      {ready ? children : null}
    </InitialParamsContext.Provider>
  );
}

export function useInitialParams() {
  return useContext(InitialParamsContext);
}
