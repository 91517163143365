import 'regenerator-runtime/runtime';
import React, { useCallback } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import 'whatwg-fetch';
import { NotifierWrapper } from 'react-native-notifier';
import * as SplashScreen from 'expo-splash-screen';
import { withOAuth } from 'aws-amplify-react-native';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { enableScreens } from 'react-native-screens';

import { AppSyncProvider } from './context/appsync';
import Navigation from './navigation/Navigator';
import { StatusBar } from './components/status-bar.component';
import { StylesProvider } from './app/SylesProvider';
import { useAppFonts } from './hooks/useFonts';
import initApp from './init';
import './i18n/config';

interface WithOAuthProps {
  oAuthUser?: CognitoUser;
  loading: boolean;
}

initApp();
enableScreens();

function AppContainer({ oAuthUser: user, loading }: WithOAuthProps) {
  const fontsLoaded = useAppFonts();

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }} onLayout={onLayoutRootView}>
      <StylesProvider>
        <NotifierWrapper>
          <StatusBar />
          {!loading && (
            <AppSyncProvider>
              <Navigation user={user} />
            </AppSyncProvider>
          )}
        </NotifierWrapper>
      </StylesProvider>
    </GestureHandlerRootView>
  );
}

export default withOAuth(AppContainer);
