import React from 'react';
import { Box, HStack, Link, useColorModeValue } from 'native-base';

import { timeFrameOptions } from '@/utils/Dashboard/utils';
import { useThemeFontColor } from '@/hooks/useThemeColor';
import { useCTSDimensions } from '@/hooks/useCTSDimensions';

interface Props {
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

export function TimeSelector({ selectedIndex, setSelectedIndex }: Props) {
  const { isDesktopWeb } = useCTSDimensions();
  const containerWidthMobile = '250px';
  const containerWidthWeb = 300;
  const customBoxWidth = isDesktopWeb ? containerWidthWeb / 5 : '50px';
  const secondaryColor = useThemeFontColor('secondary2');
  const selectedColor = useColorModeValue('white.200', 'black.600');
  const unselectedColor = useColorModeValue('white.300', 'black.700');

  return (
    <HStack
      alignItems="center"
      alignSelf="center"
      // bg="#3A394D"
      borderRadius={10}
      height={8}
      justifyContent="center"
      mt={3}
      overflow="hidden"
      width={isDesktopWeb ? containerWidthWeb : containerWidthMobile}
    >
      {timeFrameOptions.map((element, index) => {
        return (
          <Link key={index} onPress={() => setSelectedIndex(index)}>
            <Box
              alignItems="center"
              bg={selectedIndex === index ? selectedColor : unselectedColor}
              height={8}
              justifyContent="center"
              width={customBoxWidth}
              _text={{
                color: secondaryColor,
              }}
            >
              {element.label}
            </Box>
          </Link>
        );
      })}
    </HStack >
  );
}
