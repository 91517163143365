import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { TopBar } from '@/components/navigation/TopBar';

import { AlertListScreen } from '@/screens/AlertListScreen';
import { AlertDetailsScreen } from '@/screens/AlertDetailsScreen';

export type AlertsStackParamList = {
  AlertList: undefined;
  AlertDetails: { id: string };
};

const Stack = createStackNavigator<AlertsStackParamList>();

export const AlertLinkingConfig = {
  screens: {
    AlertList: {
      path: 'alerts',
      exact: true,
    },
    AlertDetails: {
      path: 'alerts/:id',
      exact: true,
    },
  },
};

export function AlertNavigator(): React.ReactElement {
  return (
    <Stack.Navigator initialRouteName="AlertList" screenOptions={{ headerShown: true }}>
      <Stack.Screen
        name="AlertList"
        component={AlertListScreen}
        options={{
          header: function Header() {
            return <TopBar title="threat alerts" />;
          },
          title: 'Threat list',
        }}
      />
      <Stack.Screen
        name="AlertDetails"
        component={AlertDetailsScreen}
        options={{
          header: function Header() {
            return <TopBar leftControl="/alerts" title="Threat Details" />;
          },
          title: 'Threat details',
        }}
      />
    </Stack.Navigator>
  );
}
