import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { DeviceDetailsScreen } from '@/screens/DeviceDetailsScreen';
import { DeviceEnrollScreen } from '@/screens/DeviceEnrollScreen';
import { DeviceListScreen } from '@/screens/DeviceListScreen';
import { TopBar } from '@/components/navigation/TopBar';

export type DeviceStackParamList = {
  DeviceList: undefined;
  DeviceDetails: { id: string };
  DeviceEnroll: { initKey: string };
};

const Stack = createStackNavigator<DeviceStackParamList>();

export const DeviceLinkingConfig = {
  screens: {
    DeviceList: {
      path: 'devices',
      exact: true,
    },
    DeviceEnroll: {
      path: 'devices/enroll',
      exact: true,
    },
    DeviceDetails: {
      path: 'devices/:id',
      exact: true,
    },
  },
};

export function DeviceNavigator(): React.ReactElement {
  return (
    <Stack.Navigator
      initialRouteName="DeviceList"
      screenOptions={{
        headerShown: true,
      }}
    >
      <Stack.Screen
        name="DeviceList"
        component={DeviceListScreen}
        options={{
          header: function Header() {
            return (
              <TopBar
                title="Devices"
              // rightControl="/devices/enroll?screen=DeviceEnroll"
              />
            );
          },
          title: 'Device list',
        }}
      />
      <Stack.Screen
        name="DeviceDetails"
        component={DeviceDetailsScreen}
        options={{
          header: function Header() {
            return <TopBar leftControl="/devices" title="Device Details" />;
          },
          title: 'Device details',
        }}
      />
      <Stack.Screen
        name="DeviceEnroll"
        component={DeviceEnrollScreen}
        options={{
          header: function Header() {
            return <TopBar leftControl="/devices" title="Enroll Device" />;
          },
          title: 'Enroll device',
        }}
      />
    </Stack.Navigator>
  );
}
