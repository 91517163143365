import React, { useEffect } from 'react';
import { useLinkTo, useRoute, RouteProp } from '@react-navigation/native';

import { CreateTenant } from '@/components/Auth/createTenant';
import { AuthStackParamList } from '@/navigation/AuthNavigator';

export function CreateTenantScreen() {
  const linkTo = useLinkTo();
  const { params } = useRoute<RouteProp<AuthStackParamList, 'AuthCreateTenant'>>();

  // Redirect to sign in if no invite code provided
  useEffect(() => {
    if (!params?.initKey) {
      linkToSignIn();
    }
  }, [params?.initKey]);

  function linkToSignIn() {
    linkTo('/auth/signIn');
  }

  function linkToVeriyEmail(email: string) {
    linkTo(`/auth/verifyEmail?email=${email}`);
  }

  return (
    <CreateTenant
      initKey={params?.initKey}
      linkToSignIn={linkToSignIn}
      linkToVerifyEmail={linkToVeriyEmail}
    />
  );
}
