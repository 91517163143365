import * as Device from 'expo-device';
import { useEffect } from 'react';
import { Platform, Vibration } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';

import {
  useUpdateUserPushTokenMutation,
  useSubscribeToFcmTopicMutation,
} from '../graphql/components';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

type EventName = 'devicealert' | 'systemalert' | 'blob' | 'bounceralert';
interface NotificationResponsePayload {
  id: string;
  confidence: number | null;
  eventId: string;
  eventName: EventName;
  PK: string;
  SK: string;
  tenantId: string;
}

export function usePushNotifications() {
  const linkTo = useLinkTo();

  const [subscribeToFcmTopic] = useSubscribeToFcmTopicMutation({
    onError: (error) => console.error('Error subscribing to FCM topic:', error),
  });

  const [updateUserToken] = useUpdateUserPushTokenMutation({
    onCompleted: (data) => {
      console.log('Completed push token update:', data?.updateUserPushToken);
    },
    onError: (error) => console.error('Error updating push token:', error),
  });

  useEffect(() => {
    async function setupMobileNotifications() {
      if (!Device.isDevice) {
        console.log('Push notifications are only supported on physical devices.');
        return;
      }

      try {
        const token = await registerForPushNotificationsAsync();
        if (token) {
          console.log('Expo Push Token:', token);
          updateUserToken({ variables: { token } });
        }

        const notificationListener =
          Notifications.addNotificationReceivedListener((notif) => {
            Vibration.vibrate(1000);
            console.log('Notification received:', notif);
          });

        const responseListener =
          Notifications.addNotificationResponseReceivedListener((response) => {
            const responseData = response.notification.request.content.data
              .payload as NotificationResponsePayload;

            if (responseData?.eventName === 'bounceralert' && responseData.eventId) {
              linkTo(`/alerts/${responseData.eventId}`);
            }
          });

        return () => {
          Notifications.removeNotificationSubscription(notificationListener);
          Notifications.removeNotificationSubscription(responseListener);
        };
      } catch (error) {
        console.error('Error setting up push notifications:', error);
      }
    }

    if (Platform.OS !== 'web') {
      setupMobileNotifications();
    } else {
      console.log('Push notifications are disabled on the web.');
    }
  }, [subscribeToFcmTopic, updateUserToken, linkTo]);
}

async function registerForPushNotificationsAsync() {
  if (Platform.OS === 'web') {
    return null;
  }

  try {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;

    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }

    if (finalStatus !== 'granted') {
      console.warn('Push notification permissions not granted.');
      return null;
    }

    const token = (await Notifications.getExpoPushTokenAsync()).data;

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    return token;
  } catch (error) {
    console.error('Error registering for push notifications:', error);
    return null;
  }
}
