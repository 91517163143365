import React from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';

import { SignIn } from '@/components/Auth/SignIn';
import { useInitialParams } from '@/hooks/useInitialParams';
import { AuthStackParamList } from '@/navigation/AuthNavigator';

export function SignInScreen() {
  // Typed navigation object
  type AuthNavigationProp = NavigationProp<AuthStackParamList, 'AuthSignIn'>;
  const navigation = useNavigation<AuthNavigationProp>();

  const [params] = useInitialParams();

  // Navigation to Forgot Password screen
  function linkToForgotPassword(email: string) {
    navigation.navigate('AuthForgotPassword', { email });
  }

  // Navigation to Create Tenant screen
  function linkToCreateTenant() {
    navigation.navigate('AuthCreateTenant', { initKey: params.initKey || '' });
  }

  // Navigation to Verify Email screen
  function linkToVerifyEmail(email: string) {
    navigation.navigate('AuthVerifyEmail', { email });
  }

  return (
    <SignIn
      showCreateTenantLink={!!params.initKey}
      linkToCreateTenant={linkToCreateTenant}
      linkToForgotPassword={linkToForgotPassword}
      linkToVerifyEmail={linkToVerifyEmail}
    />
  );
}

