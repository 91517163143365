import React, { useEffect } from 'react';
import { useNavigation, useRoute, RouteProp, NavigationProp } from '@react-navigation/native';
import { SignUp } from '@/components/Auth/SignUp';
import { AuthStackParamList } from '@/navigation/AuthNavigator';

export function SignUpScreen() {
  type AuthNavigationProp = NavigationProp<AuthStackParamList, 'AuthSignUp'>;
  const navigation = useNavigation<AuthNavigationProp>();

  const { params } = useRoute<RouteProp<AuthStackParamList, 'AuthSignUp'>>();

  useEffect(() => {
    if (!params?.inviteCode || !params?.email) {
      navigation.navigate('AuthSignIn');
    }
  }, [params?.inviteCode, params?.email, navigation]);

  function linkToSignIn() {
    navigation.navigate('AuthSignIn');
  }

  return (
    <SignUp
      inviteCode={params?.inviteCode}
      email={params?.email?.toLowerCase()}
      linkToSignIn={linkToSignIn}
    />
  );
}


