import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { TopBar } from '@/components/navigation/TopBar';

import { GeneralSettingsScreen } from '@/screens/GeneralSettingsScreen';
import { UserSettingsScreen } from '@/screens/UserSettingsScreen';
import { OrgSettingsScreen } from '@/screens/OrgSettingsScreen';
import { AppSettingsScreen } from '@/screens/AppSettingsScreen';
import { OrgInvitesScreen } from '@/screens/OrgInvitesScreen';

export type SettingsStackParamList = {
  Settings: undefined;
  UserSettings: undefined;
  OrgSettings: undefined;
  NewUser: undefined;
  AppSettings: undefined;
};

const Stack = createStackNavigator<SettingsStackParamList>();

export const SettingsLinkingConfig = {
  screens: {
    Settings: {
      path: 'settings',
      exact: true,
    },
    UserSettings: {
      path: 'settings/user',
      exact: true,
    },
    OrgSettings: {
      path: 'settings/org',
      exact: true,
    },
    NewUser: {
      path: 'settings/org/invite',
      exact: true,
    },
    AppSettings: {
      path: 'settings/app',
      exact: true,
    },
  },
};


export function SettingsNavigator() {
  return (
    <Stack.Navigator initialRouteName="Settings" screenOptions={{ headerShown: true }}>
      <Stack.Screen
        name="Settings"
        component={GeneralSettingsScreen}
        options={{
          headerShown: false,
          header: function Header() {
            return <TopBar title="Settings" info={true} />;
          },
          title: 'Settings',
        }}
      />
      <Stack.Screen
        name="UserSettings"
        component={UserSettingsScreen}
        options={{
          header: function Header() {
            return <TopBar leftControl="/settings" title="user settings" />;
          },
          title: 'User Settings',
        }}
      />
      <Stack.Screen
        name="OrgSettings"
        component={OrgSettingsScreen}
        options={{
          header: function Header() {
            return <TopBar leftControl="/settings" title="user settings" />;
          },
          title: 'User Settings',
        }}
      />
      <Stack.Screen
        name="NewUser"
        component={OrgInvitesScreen}
        options={{
          header: function Header() {
            return <TopBar leftControl="/settings/org" title="invite users" />;
          },
          title: 'New User',
        }}
      />
      <Stack.Screen
        name="AppSettings"
        component={AppSettingsScreen}
        options={{
          header: function Header() {
            return <TopBar leftControl="/settings" title="app settings" />;
          },
          title: 'App Settings',
        }}
      />
    </Stack.Navigator>
  );
}
