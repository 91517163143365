import React, { useEffect } from 'react';
import { useRoute, RouteProp, useNavigation } from '@react-navigation/native';
import { VerifyEmail } from '@/components/Auth/VerifyEmail';
import { AuthStackParamList } from '@/navigation/AuthNavigator';
import { StackNavigationProp } from '@react-navigation/stack';

type AuthNavigationProp = StackNavigationProp<AuthStackParamList, 'AuthVerifyEmail'>;

export function VerifyEmailScreen() {
  const { params } = useRoute<RouteProp<AuthStackParamList, 'AuthVerifyEmail'>>();
  const navigation = useNavigation<AuthNavigationProp>();

  useEffect(() => {
    if (!params?.email) {
      navigation.navigate('AuthSignIn');
    }
  }, [params, navigation]);

  if (!params?.email) {
    return null;
  }

  return (
    <VerifyEmail
      code={params.verificationCode}
      email={params.email}
      linkToSignIn={() => navigation.navigate('AuthSignIn')}
    />
  );
}

