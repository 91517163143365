import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  useUpdateTenantUserSettingsMutation,
  CurrentUserFragment,
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  TenantUserSettings,
} from '@/graphql/components';

import { AlertContext } from '@/context/AlertContext';
import { FormToggle } from '@/components/Forms/form-toggle.component';

interface AutoSubmitProps {
  values: TenantUserSettings;
  submitForm: () => void;
  dirty: boolean;
}

const AutoSubmit: React.FC<AutoSubmitProps> = ({ values, submitForm, dirty }) => {
  const [hasMounted, setHasMounted] = useState(false);
  const lastValuesRef = useRef(values);

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
      return;
    }

    const valuesChanged = JSON.stringify(lastValuesRef.current) !== JSON.stringify(values);

    if (valuesChanged && dirty) {
      submitForm();
      lastValuesRef.current = values;
    }
  }, [values, submitForm, dirty, hasMounted]);

  return null;
};

const SettingsSchema = Yup.object().shape({
  emailNotifications: Yup.boolean().required(),
  mobileNotifications: Yup.boolean().required(),
  webNotifications: Yup.boolean().required(),
});

interface Props {
  currentUser: CurrentUserFragment;
}

export function ToggleCard({ currentUser }: Props) {
  const CallAlert = React.useContext(AlertContext);
  const { t } = useTranslation();

  const settings = currentUser.settings;

  const notify = () => {
    CallAlert && CallAlert('Settings updated successfully', 'success');
  };

  const [updateTenantUserSettings] = useUpdateTenantUserSettingsMutation({
    onCompleted: notify,
    onError: (er) => {
      console.error(er);
      CallAlert && CallAlert('Failed to update settings', 'error');
    },
    update: (store, { data: mutationResult }) => {
      const settingsUpdate = mutationResult?.updateTenantUserSettings;
      if (!settingsUpdate) return;

      const existingData = store.readQuery<GetCurrentUserQuery>({
        query: GetCurrentUserDocument,
      });

      if (!existingData?.getCurrentUser) return;

      store.writeQuery<GetCurrentUserQuery>({
        query: GetCurrentUserDocument,
        data: {
          ...existingData,
          getCurrentUser: {
            ...existingData.getCurrentUser,
            settings: {
              __typename: 'TenantUserSettings',
              ...settingsUpdate,
            },
          },
        },
      });
    },
  });

  return (
    <Formik
      initialValues={{
        ...settings,
      }}
      validateOnBlur
      validationSchema={SettingsSchema}
      onSubmit={async (values) => {
        try {
          const input = (({ __typename, ...rest }) => rest)(values);
          await updateTenantUserSettings({ variables: { input } });
        } catch (e) {
          console.error(e);
        }
      }}
    >
      {(props) => (
        <>
          <FormToggle
            accessibilityLabel="Email notifications toggle"
            disabled={props.isSubmitting}
            id="emailNotifications"
            name={t('email')}
            testID="email-notifications-toggle"
          />

          <FormToggle
            accessibilityLabel="Web notifications toggle"
            disabled={props.isSubmitting}
            id="webNotifications"
            name={t('web')}
            testID="web-notifications-toggle"
          />

          <AutoSubmit
            values={props.values}
            submitForm={props.submitForm}
            dirty={props.dirty}
          />
        </>
      )}
    </Formik>
  );
}
